import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OrderPlacedDone from "../../../assets/order-placed-done.png";
import OrderFailedModal from "./Layout/OrderFailedModal";

const MainDivOrder = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "space-around",
};

const OrderDoneText = {
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 50px",
  color: "#181725",
  margin: "10px 0px",
};

const OrderPlacedText = {
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 50px",
  color: "#7C7C7C",
  margin: "10px 0px",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "500",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const ButtonCartSecond = {
  backgroundColor: "#fff",
  color: "#181725",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#e9e8e8",
  },
};

const InvalidProductsContainer = {
  maxHeight: "150px",
  overflowY: "auto",
  backgroundColor: "#f8f8f8",
  borderRadius: "10px",
  padding: "10px",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const InvalidProductItem = {
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "quicksand",
  color: "#D9534F",
  padding: "5px 10px",
  borderBottom: "1px solid #e0e0e0",
};

const OrderAcceptedPage = () => {
  const { status } = useParams();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const invalidProducts = location.state?.invalidProducts || [];

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
  }, []);

  return status === "1" ? (
    <Box sx={MainDivOrder}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // marginTop: "100px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "8%" }}></div>
        <img src={OrderPlacedDone} alt="Order Done" style={{ width: "65%" }} />
        <div style={{ width: "20%" }}></div>
      </div>
      <Typography sx={OrderDoneText}>Your Order has been accepted</Typography>
      <Typography sx={OrderPlacedText}>
        Your items have been placed and are on their way to being processed
      </Typography>

      {/* Invalid Products Section */}
      {invalidProducts.length > 0 && (
        <Box sx={InvalidProductsContainer}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "8px",
              color: "#D9534F",
            }}
          >
            Invalid Products (Not Placed)
          </Typography>
          {invalidProducts.map((product, index) => (
            <Typography key={index} sx={InvalidProductItem}>
              {product.productName}
            </Typography>
          ))}
        </Box>
      )}

      <Box
        sx={{
          padding: "1px 10px",
          margin: "30px 0px 10px 0px",
          textAlign: "center",
        }}
      >
        <p style={{ marginBottom: 8 }}>
          We humbly request you to rate your experience using the button below
        </p>
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/campaign/ordercomplete`, {
              state: { value: location?.state?.value, orderId },
            });
          }}
        >
          Feedback
        </Button>
      </Box>
      <Box sx={{ padding: "0px 10px", margin: "10px 0px" }}>
        <Link to="/home">
          <Button sx={ButtonCartSecond}>Back to home</Button>
        </Link>
      </Box>
    </Box>
  ) : (
    <OrderFailedModal />
  );
};

export default OrderAcceptedPage;
