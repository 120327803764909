import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import server from "../server";
import { eUserLogin } from '../../analytics/logEvent'

const Login = () => {
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const [loginDetails, setLoginDetails] = useState({
    name: "",
    password: "",
  });
  const [cust_data, setCust_data] = useState({
    first_name: "",
    last_name: "",
    role: "USER",
    phone: "",
    password: "",
    is_superuser: true,
  });
  const [login, setLogin] = useState(false);
  const handleInput = (key, value) => {
    setLoginDetails({
      ...loginDetails,
      [key]: value,
    });
  };
  const handleInput1 = (key, value) => {
    setCust_data({
      ...cust_data,
      [key]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(`${server}/auth/admin/login`, {
        password: loginDetails.password,
        username: loginDetails.name,
      });
      if (result?.data?.message == "eyJ1c2VySWQiOiI2NTgyZD") {
        localStorage.setItem("adminuser", "present");
        localStorage.setItem("user", result.data.user._id);
        localStorage.setItem("accessToken", result.data.accessToken);
        context?.setUser(true);
        eUserLogin(result.data.user._id)
        // history.go(0);
        // navigate("/dashboard");
      } else {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // console.log(err);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  //this function is not used since admin is singular. no backend needed currently for admin login
  // const handleSignup = (e) => {
  // 	console.log(loginDetails, cust_data);
  // 	e.preventDefault();

  // 	axios
  // 		.post(`${server}/auth/user_register`, { cust_data })
  // 		.then((res) => {
  // 			console.log(res);
  // 			axios
  // 				.post(`${server}/auth/login`, loginDetails)
  // 				.then((res) => {
  // 					console.log(res);
  // 					toast.success("Signed up successfully!", {
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: true,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 						theme: "light",
  // 					});
  // 					localStorage.setItem("access", res.data.tokens.access);
  // 					localStorage.setItem(
  // 						"refresh",
  // 						res.data.tokens.refresh
  // 					);
  // 					localStorage.setItem("id", res.data.id);
  // 					localStorage.setItem("adminuser", "present");

  // 					context.setUser(true);
  // 					navigate("/dashboard");
  // 				})
  // 				.catch((err) => {
  // 					toast.error("Something Went Wrong", {
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: true,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 						theme: "light",
  // 					});
  // 					console.log(err);
  // 				});
  // 		})
  // 		.catch((err) => {
  // 			toast.error("Something Went Wrong", {
  // 				position: "top-right",
  // 				autoClose: 5000,
  // 				hideProgressBar: true,
  // 				closeOnClick: true,
  // 				pauseOnHover: true,
  // 				draggable: true,
  // 				progress: undefined,
  // 				theme: "light",
  // 			});
  // 			console.log(err);
  // 		});

  // 	// console.log(signupDetails);
  // };

  useEffect(() => {
    if (localStorage.getItem("adminuser")) navigate("/dashboard");
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f4f5fa" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#d8d6d6",
        }}
      >
        {/* {login ? ( */}
        <Box className="main-login-div">
          <h2>Welcome Back!</h2>
          <br />
          <h3 style={{ color: "grey" }}>Urban Bazaar</h3>
          <h4 style={{ color: "grey" }}>Login to your account</h4>
          <br />
          <form onSubmit={handleLogin}>
            <input
              className="login-input"
              placeholder="Name"
              value={loginDetails?.name}
              onChange={(e) => handleInput("name", e.target.value)}
              required
            />
            <br />
            <input
              className="login-input"
              placeholder="Password"
              value={loginDetails?.password}
              onChange={(e) => handleInput("password", e.target.value)}
              required
            />
            <br />
            {/* <h5 style={{ color: "grey" }}>Dont't have an account? <span onClick={() => setLogin(false)} style={{ color: "#5570f1", cursor: "pointer" }}>Signup</span></h5> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                type="submit"
                style={{
                  textAlign: "center",
                  backgroundColor: "#5570f1",
                  color: "white",
                  border: "none",
                  padding: "10px 1rem",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  width: "10rem",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
        {/* ) : ( */}
        {/* <Box className="main-login-div">
						<h2>
							Get Started with{" "}
							<span style={{ color: "#5570f1" }}>
								Kamao Kisan
							</span>
						</h2>
						<br />
						<h4 style={{ color: "grey" }}>Create your account</h4>
						<form onSubmit={handleSignup}>
							<br />
							<input
								className="login-input"
								placeholder="First Name"
								value={cust_data?.first_name}
								onChange={(e) =>
									handleInput1("first_name", e.target.value)
								}
								required
							/>
							<br />
							<input
								className="login-input"
								placeholder="Last Name"
								value={cust_data?.last_name}
								onChange={(e) =>
									handleInput1("last_name", e.target.value)
								}
								required
							/>
							<br />
							<input
								className="login-input"
								placeholder="Phone Number"
								value={cust_data?.phone}
								onChange={(e) => {
									handleInput("phone", e.target.value);
									handleInput1("phone", e.target.value);
								}}
								required
							/>
							<br />
							<input
								className="login-input"
								placeholder="Password"
								value={cust_data?.password}
								onChange={(e) => {
									handleInput("password", e.target.value);
									handleInput1("password", e.target.value);
								}}
								required
							/>
							<br />
							<h5 style={{ color: "grey" }}>
								Already have an account?{" "}
								<span
									onClick={() => setLogin(true)}
									style={{
										color: "#5570f1",
										cursor: "pointer",
									}}
								>
									Login
								</span>
							</h5>
							<div className="login-button-div">
								<button type="submit" className="login-button">
									Submit
								</button>
							</div>
						</form>
					</Box> */}
        {/* )} */}
      </Box>
    </Box>
  );
};

export default Login;
