import { getAnalytics, logEvent } from 'firebase/analytics';
import { EVENTS } from "./events";
import app from '../firebase'

const analytics = getAnalytics(app)

export const eAppLaunch = () => {
    logEvent(analytics, EVENTS.APP_LAUNCH);
}

export const eUserLogin = (userId) => {
    const params = { type: 'Customer', userId }
    logEvent(analytics, EVENTS.USER_LOGIN, params)
}

export const eProductAdded = (productId, vendor, name) => {
    const params = { productId, vendor, name }
    logEvent(analytics, EVENTS.PRODUCT_ADDED, params);
}

export const eProductRemoved = (productId, vendor, name) => {
    const params = { productId, vendor, name }
    logEvent(analytics, EVENTS.PRODUCT_REMOVED, params);
}

export const eProductSearch = (searchQuery) => {
    logEvent(analytics, EVENTS.PRODUCT_SEARCHED, { searchQuery });
}

export const eOrderPlaced = ( userId, orderId, ammount, pincode ) => {
    const params = { userId, orderId, ammount, pincode }
    logEvent(analytics, EVENTS.ORDER_PLACED, params);
}

export const eOrderReturn= ( userId, orderId, ammount, pincode ) => {
    const params = { userId, orderId, ammount, pincode }
    logEvent(analytics, EVENTS.ORDER_RETURNED, params);
}

export const eOrderCancelled= ( userId, orderId, ammount, pincode ) => {
    const params = { userId, orderId, ammount, pincode }
    logEvent(analytics, EVENTS.ORDER_CANCELLED, params);
}

export const ePageVisit = (pageName) => {
    logEvent(analytics, EVENTS.PAGE_VISIT, pageName);
}
