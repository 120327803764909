import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

const EmployeeModal = ({
  open,
  handleClose,
  handleSubmit,
  EmployeeInformation,
  setEmployeeInformation,
  isEdit,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [newData, setNewData] = useState();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setEmployeeInformation((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90vw", sm: "60vw", md: "40vw" },
          bgcolor: "background.paper",
          borderRadius: "16px",
          boxShadow: 24,
          p: 4,
          background: "linear-gradient(135deg, #e0eafc, #cfdef3)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            {isEdit ? "Edit Employee" : "Create New Employee"}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <CloseIcon sx={{ color: "#555" }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            label="Employee Name"
            name="name"
            value={EmployeeInformation.name}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ccc" },
                "&:hover fieldset": { borderColor: "#888" },
                "&.Mui-focused fieldset": { borderColor: "#5570F1" },
              },
            }}
          />

          <TextField
            label="Mobile"
            name="mobile"
            value={EmployeeInformation.mobile}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ccc" },
                "&:hover fieldset": { borderColor: "#888" },
                "&.Mui-focused fieldset": { borderColor: "#5570F1" },
              },
            }}
          />

          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={EmployeeInformation.password}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ccc" },
                "&:hover fieldset": { borderColor: "#888" },
                "&.Mui-focused fieldset": { borderColor: "#5570F1" },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Role Selection Dropdown */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Select Role</InputLabel>
            <Select
              name="role"
              value={EmployeeInformation.role}
              onChange={handleInputChange}
              variant="outlined"
            >
              <MenuItem value="DELIVERY">Delivery</MenuItem>
              <MenuItem value="PICKER">Picker</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#5570F1",
              color: "white",
              fontWeight: 600,
              padding: "12px 24px",
              borderRadius: "8px",
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": { backgroundColor: "#405ABD" },
              mt: 2,
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmployeeModal;
