import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";

const OrderImageView = ({ imageUrls, imageCaptureTime }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const openModal = (imageUrl, time) => {
    setSelectedImage(imageUrl);
    setSelectedTime(time ? format(new Date(time), "hh:mm:ss a") : null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedTime(null);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
        {imageUrls.length > 0 ? (
          imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`thumbnail-${index}`}
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "8px",
                marginRight: "10px",
              }}
              onClick={() => openModal(url, imageCaptureTime)}
            />
          ))
        ) : (
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              backgroundColor: "#e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#888",
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            No Image
          </div>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="large-view"
            style={{
              maxWidth: "100vw",
              maxHeight: "100vh",
              width: "auto",
              height: "auto",
              objectFit: "cover",
            }}
          />

          <Typography
            sx={{
              position: "absolute",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              padding: "6px 12px",
              borderRadius: "6px",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
            }}
          >
            {selectedTime ? `Captured: ${selectedTime}` : "No time recorded"}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default OrderImageView;
