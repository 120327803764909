import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../Components/server";
import DashboardContext from "../Context/DashboardContext";
import AllOrders from "./Layout/AllOrders";
import Marketing from "./Layout/Marketing";
import Sales from "./Layout/Sales";
// import DashboardState from "../Context/DashboardState";

const Dashboard = () => {
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const {
    customers,
    setLoading,
    getCustomers,
    orders,
    getOrders,
    getProducts,
    products,
    crops,
    getCrops,
    posts,
    getPosts,
    returns,
    getReturns,
  } = context;

  const [salesData, setSalesData] = useState(null);

  const fetchSalesMetrics = async () => {
    try {
      setLoading(true);
      const result = await axios.get(`${server}/sales-metrics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const salesData = result.data.data;

      setSalesData(salesData);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      fetchSalesMetrics();
      getCustomers();
      getOrders();
      // getProducts();
      getReturns();
    }
    //  getCrops();
    //  getPosts();
  }, []);

  return (
    <Box>
      <Box className="dashboard-main">
        <Box className="">
          {salesData && (
            <Sales
              orders={orders}
              returns={returns}
              customerCount={customers?.length}
              salesData={salesData}
            />
          )}
        </Box>

        {/* <Box className="">
					<Customers customers={customers} />
				</Box> */}
        <Box>
          <Box>
            <Box className="allorders">
              <AllOrders orders={orders} type={"Orders"} />
            </Box>
            <Box className="marketing tile">
              <Marketing orders={orders} label={"Orders"} />
            </Box>
          </Box>

          <Box>
            <Box className="allorders">
              <AllOrders orders={returns} type={"Returns"} />
            </Box>
            <Box className="marketing tile">
              <Marketing orders={returns} label={"Returns"} />
            </Box>
          </Box>
        </Box>

        {/* <Box className="allproducts">
					<Allproducts products={products} />
				</Box> */}

        {/* <Box className="abandoned">
					<Abandoned crops={crops} posts={posts} />
				</Box> */}
        {/* <Box className="recentorders">
					<RecentOrders orders={orders} />
				</Box> */}
        {/* <Box className="summary">
					<Summary orders={orders} />
				</Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
{
  /* {
          orders ? <Box className="marketing">
            <Marketing orders={orders} />
          </Box>
            : <Box className="marketing" sx={
              {
                display: "flex",
                flexDirection: "column",

              }
            }>
              Marketing
              <div style={
                {
                  display: "flex",
                  // width: "20rem", height: "20rem",
                  alignItems: "center",
                  justifyContent: "center"

                }}>
                <img src={Blank} alt="" />
              </div>
            </Box>


        } */
}
