import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Bag } from "../../../../assets/cart.svg";
import { weightvariants } from "../../../../assets/variants";
import { fetchData } from "../../../../redux/slices/dataSlice";
import { eProductAdded } from '../../../../analytics/logEvent'

const SizeSelectionModal = ({
  open,
  handleClose,
  productDetails,
  setCount,
  selectedVariant,
  setSelectedVariant,
  setQuantity,
}) => {
  const weights = weightvariants.weights;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [selectedVariant, setSelectedVariant] = useState(1);

  const handleChange = (event, i) => {
    event.preventDefault();
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    const exists = cartarr?.some(function (element) {
      console.log("element0", element[0]);
      console.log("productid", productDetails._id);
      if (element[0] == productDetails._id) {
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productDetails.price * element[1] * element[2],
          );

        element[1] = event.target.value;

        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price * element[1] * element[2],
          );

        return true;
      }

      // else setQuantityButton(false)
    });

    localStorage.setItem("cart", JSON.stringify(cartarr));

    setSelectedVariant(event.target.value);
    setQuantity(1);
  };

  const addtocart = async (e) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
    e.preventDefault();
    let arr;
    // handleOpen()
    //using JSON of weights as given on 22 july
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr?.some(function (element) {
        if (element[0] == productDetails._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            element[1] = parseInt(selectedVariant);
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetails.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setCount(1);
          setItems((current) => current + 1);
          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (productDetails?.subCategoryId?.name?.includes("Loose")) {
          temparr = [productDetails._id, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetails.price * selectedVariant,
            );
        } else {
          temparr = [productDetails._id, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productDetails.price,
          );
        }
        cartarr?.push(temparr);
        setCount(1);
        setQuantityButton(true);
        setItems((current) => current + 1);
      }
      eProductAdded(productDetails._id, 'UrbanBazaar', productDetails?.name)
    }


    localStorage.setItem("cart", JSON.stringify(cartarr));
    dispatch(fetchData())
      .unwrap()
      .then((data) => {
        console.log("Data fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    //latest code ends here from 22 july

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // }
  };
  console.log("Pd is ", productDetails);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100vw",
          bgcolor: "background.paper",
          borderTopLeftRadius: "5%",
          borderTopRightRadius: "5%",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "quicksand",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Choose an available size
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ overflow: "scroll" }}>
          <FormControl sx={{ width: "100%" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  overflow: "scroll",
                }}
              >
                {weights &&
                  weights.map((currweight, i) => (
                    <div
                      key={i}
                      style={{
                        width: "40%",
                        border: "1px solid #EAEAEA",
                        padding: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "quicksand",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          {currweight >= 100 ? (
                            <>{currweight} gm</>
                          ) : (
                            <>{currweight} kg</>
                          )}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "quicksand",
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "rgba(243, 122, 32, 1)",
                            }}
                          >
                            ₹{" "}
                            {currweight >= 100
                              ? (
                                  productDetails?.price *
                                  (currweight / 1000)
                                ).toFixed(2)
                              : productDetails?.price * currweight}
                          </Typography>
                          <FormControlLabel
                            value={currweight}
                            control={<Radio />}
                            sx={{
                              margin: "0px",
                              padding: "5px",
                            }}
                          />
                        </Box>
                      </Box>
                    </div>
                  ))}
                <Box sx={{ width: "40%" }}></Box>
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <Button
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              addtocart(e);
            }}
          >
            <Box></Box>
            <Typography>Add to Bag</Typography>
            <Bag
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "14px",
                height: "14px",
                margin: "-3px 5px 0px 0px",
              }}
            />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SizeSelectionModal;
