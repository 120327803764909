import { Box, Button, FormControl, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../../redux/slices/dataSlice";
import { NavBarContext } from "../../Navbar/Navbar";
import SizeSelectionModal from "./SizeSelectionModal";
import { eProductAdded } from '../../../../analytics/logEvent'

const ProductCard = ({ product }) => {
  const { name, imageUrl, quantity, weight, price, mrpPrice, discount } =
    product;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [quantitybutton, setQuantityButton] = useState(false);
  const { setItems, wishList, fetchWishList } = useContext(NavBarContext);
  const [selectedVariant, setSelectedVariant] = useState(1);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1); // Increase the count
  };

  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0)); // Decrease the count, but not below 0
  };

  const displaybutton = () => {
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    setQuantityButton(false);

    //22 july code below
    cartarr?.some(function (element) {
      if (element[0] == product._id) {
        // console.log('changing button' + element[1])
        if (element.length === 3) {
          if (parseInt(element[2]) !== 0) {
            setQuantityButton(true);
            setCount(parseInt(element[2]));
            return true;
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            setQuantityButton(true);
            setCount(parseInt(element[1]));
            return true;
          }
        }
      }
      // else setQuantityButton(false)
    });
  };

  const handleAddClick = () => {
    if (count === 0) {
      setCount(1); // Set count to 1 if it's currently 0
    }
  };

  const changequantity = (e, i) => {
    e.preventDefault();
    // console.log('quant change')
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below

    if (i == 1) {
      //plus button
      if (product.buyinglimit === count && product.buyinglimit !== 0) {
        toast.warning("Buying Limit Exceeded", topRightToastStyles);
        return;
      }

      setCount((current) => current + 1);
      //change in localstorage also

      //check if variant or product FIRST then proceed
      //it is a product not a variant
      const exists = cartarr?.some(function (element) {
        if (element[0] == product._id) {
          // console.log("found prod for increase")
          if (product.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  product.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  product.price * selectedVariant,
              );
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) + product.price,
            );
            element[1] = (parseInt(element[1]) + 1).toString();
          }

          return true;
        }
      });

      // toast.success("Quantity Increased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      setCount((current) => current - 1);

      //it is a product not a variant
      const exists = cartarr?.some(function (element) {
        if (element[0] == product._id) {
          if (product?.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) - 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  product.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  product.price * selectedVariant,
              );
            if (element[2] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) - product.price,
            );
            element[1] = (parseInt(element[1]) - 1).toString();
            if (element[1] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
          }
          return true;
        }
      });

      // toast.success("Quantity Decreased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
    localStorage.setItem("cart", JSON.stringify(cartarr));
  };

  const addtocart = async (e) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
    e.preventDefault();
    let arr;
    // handleOpen()
    //using JSON of weights as given on 22 july
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr?.some(function (element) {
        if (element[0] == product._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            element[1] = parseInt(selectedVariant);
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  product.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  product.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) + product.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setCount(1);
          setItems((current) => current + 1);
          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (product?.subCategoryId?.name?.includes("Loose")) {
          temparr = [product._id, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                product.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                product.price * selectedVariant,
            );
        } else {
          temparr = [product._id, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) + product.price,
          );
        }
        cartarr?.push(temparr);
        setCount(1);
        setQuantityButton(true);
        setItems((current) => current + 1);
      }
      eProductAdded(product._id, 'UrbanBazaar', name)
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));
    dispatch(fetchData())
      .unwrap()
      .then((data) => {
        console.log("Data fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    //latest code ends here from 22 july

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // }
  };

  useEffect(() => {
    displaybutton();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.04) 2px 2px 8px",
        backgroundColor: "#fff",
        padding: 1,
        position: "relative",
        overflow: "hidden",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
        height: "100%", // Full height to match parent
      }}
    >
      {/* Discount Badge */}
      {mrpPrice - price > 0 && (
        <Box
          sx={{
            position: "absolute",
            left: 2,
            top: 2,
            zIndex: 10,
            backgroundColor: "#256fef",
            color: "white",
            padding: "2px 6px",
            borderRadius: "4px",
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
        >
          Save {mrpPrice - price}Rs
        </Box>
      )}

      {/* Product Image */}
      <Box
        component="img"
        src={imageUrl}
        alt={name}
        sx={{
          width: "100%",
          height: "auto",
          aspectRatio: "1 / 1",
          objectFit: "cover",
          borderRadius: "8px",
          marginBottom: 1,
        }}
      />

      {/* Product Info */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            lineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          {quantity}
        </Typography> */}
        {product?.subCategoryId?.name?.includes("Loose") && (
          <FormControl
            size="small"
            sx={{ width: "100%", margin: "10px 0px" }} // Full width of parent
          >
            <Box
              sx={{
                width: "50%",
                border: "1px solid #EAEAEA",
                padding: "6px 10px",
                textAlign: "left",
              }}
              onClick={handleOpen}
            >
              {selectedVariant >= 100
                ? `${selectedVariant}gm`
                : `${selectedVariant}kg`}
            </Box>
          </FormControl>
        )}
        {weight && (
          <Typography variant="body2" color="text.secondary">
            {weight} kg
          </Typography>
        )}
      </Box>

      {/* Pricing Info */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", color: "black" }}
          >
            ₹{price}
          </Typography>
          {mrpPrice && (
            <Typography
              variant="body2"
              sx={{ textDecoration: "line-through", color: "grey" }}
            >
              ₹{mrpPrice}
            </Typography>
          )}
        </Box>{" "}
        {quantitybutton ? (
          <>
            <Button
              onClick={(e) => changequantity(e, 0)}
              variant="outlined"
              size="small"
              sx={{ borderRadius: "4px", minWidth: "20px" }}
            >
              -
            </Button>
            <Typography variant="body2" sx={{ margin: "0 10px" }}>
              {count} {/* Display the quantity */}
            </Typography>
            <Button
              onClick={(e) => changequantity(e, 1)}
              variant="outlined"
              size="small"
              sx={{ borderRadius: "4px", minWidth: "20px" }}
            >
              +
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "4px",
              padding: "6px 12px",
              fontSize: "0.875rem",
              textTransform: "none",
            }}
            onClick={(e) => {
              if (product?.subCategoryId?.name?.includes("Loose")) {
                handleOpen();
              } else {
                addtocart(e);
              }
            }}
          >
            ADD
          </Button>
        )}
      </Box>

      <SizeSelectionModal
        open={open}
        handleClose={handleClose}
        productDetails={product}
        setCount={setCount}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        setQuantity={setCount}
      />
    </Box>
  );
};

export default ProductCard;
