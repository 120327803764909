export const EVENTS = {
    APP_LAUNCH: 'app_launch',
    USER_LOGIN: 'user_login',
    PRODUCT_ADDED: 'product_added',
    PRODUCT_REMOVED: 'product_removed',
    PRODUCT_SEARCHED: 'product_searched',
    ORDER_PLACED: 'order_placed',
    ORDER_CANCELLED: 'order_cancelled',
    ORDER_RETURNED: 'order_returned',
    PAGE_VISIT: 'page_visit'
}
